import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import store from "../Firebase/FirebaseStorage";
import { ref, listAll, getDownloadURL, getBlob } from "firebase/storage";

function DetalhesCarros(props) {
    let placa = props.placa;

    const [images, setImages] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [currentPicture, setCurrentPicture] = useState("");
    const [loaded, setLoaded] = useState(false);

    const handleShow = (picture) => {
        setCurrentPicture(picture);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        const storeRef = ref(store, `veiculos/${placa}`); // Replace with your storage path
        const urls = [];

        listAll(storeRef)
            .then((res) => {
                const imagePromises = res.items.map((itemRef) =>
                    getDownloadURL(itemRef).then((url) => {
                        urls.push(url);
                        return (
                            <Carousel.Item
                                key={url}
                                onClick={() => {
                                    handleShow(url);
                                }}
                            >
                                <img
                                    style={{
                                        borderRadius: "25px",
                                        height: "600px",
                                        width: "600px",
                                        objectFit: "cover",
                                    }}
                                    className="d-block w-100"
                                    src={url}
                                    alt={`Image ${urls.length}`}
                                />
                            </Carousel.Item>
                        );
                    })
                );

                Promise.all(imagePromises).then((carouselImages) => {
                    setImages(carouselImages);
                    setLoaded(true)
                });
            })
            .catch((error) => {
                console.error("Error fetching images:", error);
            });
    }, []);

    const ImageCarousel = () => {
        if (images.length > 0) {
            return (
                <div>
                    <Carousel>{images}</Carousel>
                    <Modal show={showModal} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            {/* <Modal.Title>
                                Full Screen Picture
                            </Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                            <img src={currentPicture} width={"100%"} />
                        </Modal.Body>
                    </Modal>
                </div>
            );
        } else if (loaded) {
            return (
                <div
                    className="w-100 text-center h-50 d-flex align-items-center justify-content-center rounded"
                    style={{ backgroundColor: "#e5e5e5" }}
                >
                    <div className="col">
                        <i className="bi bi-image fs-1"></i>
                        <h5>Sem imagems disponíves</h5>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <Container>
                        <Spinner animation="border" role="status" size="md">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <br />
                        Carregando Imagens...
                    </Container>
                </>
            );
        }
    };

    return (
        <div className="p-3">
            <ImageCarousel />
        </div>
    );
}

export default DetalhesCarros;
