import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import Home from "./Components/Home";
import {
    RouterProvider,
    createBrowserRouter,
    redirect,
} from "react-router-dom";

const router = createBrowserRouter(
    [
        {
            path: "/",
            // loader: loader,
            element: <Home />,
            // errorElement: <MainErrorPage />,
        },
    ],
    { basename: "/" }
);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);
