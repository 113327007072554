import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";

function MainNavBar() {
    return (
        <Navbar className="bg-info">
            <Container>
                <Navbar.Brand className="mx-auto">
                    <Image
                        src="assets/splash.png"
                        width="75"
                    />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default MainNavBar;
