const firebaseConfig = {
    apiKey: "AIzaSyC4KLyIkIC1xK4lLFI-A6vZoLlqvdHIkOY",
    authDomain: "ver-carro.firebaseapp.com",
    databaseURL: "https://ver-carro-default-rtdb.firebaseio.com",
    projectId: "ver-carro",
    storageBucket: "ver-carro.appspot.com",
    messagingSenderId: "131436743292",
    appId: "1:131436743292:web:0d9ddff66c2b157307d7f9",
    measurementId: "G-SBNXS6Y376"
  };

export default firebaseConfig;