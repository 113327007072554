import React from "react";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Downloads() {
    return (
        <>
            <Container className="mt-4">
                <h3>Comece a usar agora mesmo!</h3>
                <Row className="d-flex justify-content-center ">
                    <Col sm={4} className="py-3">
                        <a
                            href="https://play.google.com/store/games"
                            target="_blank"
                        >
                            <Image
                                src="assets/badge_android_resize.png"
                                className="w-100"
                                style={{ maxWidth: "250px" }}
                            />
                        </a>
                    </Col>
                    <Col sm={4} className="py-3">
                        <a
                            href="https://www.apple.com/br/app-store/"
                            target="_blank"
                        >
                            <Image
                                src="assets/badge_apple_resize.png"
                                className="w-100"
                                style={{ maxWidth: "250px" }}
                            />
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Downloads;
