import React, { useEffect, useState } from "react";
import MainNavBar from "./MainNavBar";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

import db from "../Firebase/FirebaseDB";
import { onValue, ref } from "firebase/database";
import DetalhesCarros from "./Carousel";
import Downloads from "./Downloads";
import Contato from "./Contato";

function Home() {
    const [carData, setCarData] = useState([]);
    const [infAd, setInfAd] = useState([]);
    const [placa, setPlaca] = useState("");

    useEffect(() => {
        let placa = getParameterFromURL("placa");

        setPlaca(placa.replace("-", ""));

        if (placa !== "") {
            const configData = ref(db, `/veiculos/${placa.replace("-", "")}`);

            onValue(configData, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const infAd = Object.keys(data).filter((key) =>
                        key.startsWith("InfAd")
                    );
                    let marca = String(data.veiculo);
                    marca = marca.split("/");

                    if (marca[0] === "I") {
                        marca = String(marca[1]).split(" ");
                        marca = marca[0];
                    } else {
                        marca = marca[0];
                    }
                    data.veiculo = marca;

                    if (data.observacoes) {
                        console.log("a");
                    }

                    setInfAd(infAd);
                    setCarData(data);
                }
            });
        }
    }, []);

    function formataPlaca(plate) {
        const cinza = /^[A-Z]{3}\d{4}$/;

        if (cinza.test(plate)) {
            return plate.replace(/(\w{3})(\d{4})/, "$1-$2");
        } else {
            return plate;
        }
    }

    const getParameterFromURL = (name) => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.size > 0) {
            return urlParams.get(name).toUpperCase();
        } else {
            return "";
        }
    };

    const CarDataRow = ({ field, value }) => {
        return (
            <tr key={field}>
                <td className="fw-bold">{field}</td>
                <td>{value}</td>
            </tr>
        );
    };

    // const CarDataRowMultipleValues = ({ field, value }) => {
    //     if (!value) value = [];

    //     if (value.length > 0) {
    //         return (
    //             <tr key={field}>
    //                 <td className="fw-bold">{field}</td>
    //                 <td>
    //                     <ul>
    //                         {value.map((val) => (
    //                             <li key={val}>{val}</li>
    //                         ))}
    //                     </ul>
    //                 </td>
    //             </tr>
    //         );
    //     } else {
    //         return <></>;
    //     }
    // };

    const PillList = ({ field, value }) => {
        if (!value) value = [];

        if (value.length > 0) {
            return (
                <>
                    <tr key={field}>
                        <td className="fw-bold">{field}</td>
                        <td>
                            <ul>
                                {/* {value.map((val) => (
                                    <li key={val}>{val}</li>
                                ))} */}
                                {value.map((item, index) => (
                                    <span
                                        key={index}
                                        className="badge bg-primary rounded-pill m-1"
                                        style={{ fontSize: "0.8em" }}
                                    >
                                        {item}
                                    </span>
                                ))}
                            </ul>
                        </td>
                    </tr>
                </>
            );
        } else {
            return <></>;
        }
    };

    const CarDataTable = ({ carData }) => {
        if (!carData["placa"]) {
            return <></>;
        }
        return (
            <Container>
                <DetalhesCarros placa={placa} />
                <Table striped bordered hover className="my-3">
                    <tbody>
                        <CarDataRow
                            field="Preço"
                            value={parseInt(carData["preco"]).toLocaleString(
                                "pt-BR",
                                {
                                    style: "currency",
                                    currency: "BRL",
                                }
                            )}
                        />
                        <CarDataRow field="Placa" value={formataPlaca(carData["placa"])} />
                        <CarDataRow field="Ano" value={carData["anoMod"]} />
                        {infAd.includes("InfAd_km") && (
                            <CarDataRow
                                field="Km"
                                value={
                                    parseInt(
                                        carData["InfAd_km"]
                                    ).toLocaleString("pt-BR") + " km"
                                }
                            />
                        )}
                        {infAd.includes("InfAd_cambio") && (
                            <CarDataRow
                                field="Câmbio"
                                value={carData["InfAd_cambio"]}
                            />
                        )}
                        <CarDataRow
                            field="Combustível"
                            value={carData["combustivel"]}
                        />
                        <CarDataRow field="Cor" value={carData["corPred"]} />
                        <CarDataRow field="Marca" value={carData["veiculo"]} />
                        <CarDataRow field="Modelo" value={carData["nomeExb"]} />

                        {carData.observacoes && (
                            <CarDataRow
                                field="Observacões"
                                value={carData["observacoes"]}
                            />
                        )}

                        <PillList
                            field="Opcionais"
                            value={carData["opcionais"]}
                        />
                    </tbody>
                </Table>

                <Contato phone={carData.dono[0]} />
            </Container>
        );
    };

    return (
        <>
            <MainNavBar />

            <CarDataTable carData={carData} />

            <Downloads />
        </>
    );
}

export default Home;
