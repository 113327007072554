import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Contato(props) {
    let phoneNumber = String(props.phone);
    const whatsappNumber = phoneNumber.replace("+", "")
    phoneNumber = phoneNumber.slice(3);
    phoneNumber = `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(
        2,
        7
    )}-${phoneNumber.substring(7)}`;

    const abrirWhatsapp = () => {
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, "_blank");
    };

    return (
        <Container>
            <div className="d-flex align-items-center justify-content-center fs-3 py-3">
                <i class="bi bi-telephone-fill"></i> &nbsp;{" "}
                <span>{phoneNumber}</span>
            </div>
            <a className=""></a>
            <Button variant="success" size="lg" onClick={abrirWhatsapp}>
                <i class="bi bi-whatsapp"></i> Chamar no Whatsapp
            </Button>
        </Container>
    );
}

export default Contato;
